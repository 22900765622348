import React from "react"
import ChartistGraph from 'react-chartist';
import Ample from "../components/ample/ample"
import {formatCurrency, formatPct, validateNumericInput, validateStringInput, allDatesInRange} from "../utils"
import ChainPicker from "../components/chainpicker"
import TextField from '@mui/material/TextField';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import ExplainerPopover from "../components/general/explainerpopover"
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import {OptionType,OptionStrategy,optionName,strategyName} from "../data/options";
import "../css/chartist_overrides.css";
import ChartParams from "../chartparams/spreadfinder.json";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import {logEvent} from "../analytics";

export default class SpreadFinderPage extends React.Component {
  state = {
    selected_ticker: "AMZN",
    selected_expiry: "",
    strikewidth: 5.0,
    resultsVisible : false,
    optiondata: [],
    pricedata: [],
    selected_bottom_strike: null,
    backdropActive:false,    
    displayed_ticker: "AMZN",
    displayed_expiry: "", 
    selected_option_type:OptionType.Call, 
    selected_strategy:OptionStrategy.DebitSpread,
    displayed_strategy: null,
    chartdata:{labels:[],series:[]}
  }

  // #region form events
  strikeWidthChanged= (event) => {
    this.setState({strikewidth:event.target.value});
  }
  
  chainChanged = (new_chain) => {
    this.setState({
      selected_ticker:new_chain.ticker,
      selected_expiry:new_chain.expiry,
      selected_option_type:new_chain.option_type
    }); 
  }
    
  strategyChanged = (event) => {
    this.setState({selected_strategy:event.target.value});
    logEvent("strategyChanged", {selected_strategy:event.target.value});
  }
  
  resetForm = (event) => {
    this.setState({optiondata:[]});
    logEvent("resetForm");
  }

  formValid = () => (validateStringInput(this.state.selected_ticker.toString())
                  && validateStringInput(this.state.selected_expiry.toString() )
                  && validateNumericInput(this.state.strikewidth,false,false));
    
  formSubmitted = (event) => {
    //this.setState({backdropActive:true, optiondata:[]})
    //clear out the existing price data
    this.setState({backdropActive:true, optiondata:[],pricedata:[],selected_bottom_strike:null})

    //make sure both calls are against the same ticker (in case the drop-down changes in between)
    let ticker = this.state.selected_ticker;
    let expiry = this.state.selected_expiry;
    let width = this.state.strikewidth;
    let option_type=this.state.selected_option_type;
    let strategy=this.state.selected_strategy;

    //request option data and the price data for the underlying stock, in parallel
    Promise.all([this.requestOptionData(ticker,expiry,width,option_type,strategy),this.requestPriceData(ticker)])
    //once both data sets have been retreived, we can show the results
    .then(()=>this.setState({backdropActive:false}));
  }
  // #endregion
  
  // #region Fetch Data
  /*
    Request the available vertical spreads for the given ticker / expiration and width
      Return a promise, as the caller will need to react when this is done
  */
  requestOptionData = (ticker, expiry,width,option_type,strategy) => {
    const url = `${process.env.GATSBY_TICKER_API_ENDPOINT}/${ticker}/verticals/?expiry=${expiry}&width=${width}&strategy=${strategy}&option_type=${option_type}`
    const params = {url:url,ticker:ticker, width:width, expiry:expiry, option_type:option_type, strategy:strategy }

    let p = fetch(url)
        .then(res => res.json())
        .then((data) => data.filter((s)=>(s.mark < width)))
        .then((data) => this.setState({ optiondata: data, displayed_ticker:ticker, displayed_width:width, displayed_expiry:expiry, displayed_option_type:option_type, displayed_strategy:strategy }
                      ,()=>{this.selectDefaultSpread()}
                        ))
        .then(()=>logEvent("requestVerticals_Success", params))
        .catch((e)=>logEvent("requestVerticals_Failed", params));

     return p;
  }
  /*
    Request the available vertical spreads for the given ticker / expiration and width
      Return a promise, as the caller will need to react when this is done
  */
   requestPriceData = (ticker) => {
    let url = `${process.env.GATSBY_TICKER_API_ENDPOINT}/${ticker}/prices`
    const params = {url:url, ticker:ticker};

    let p = fetch(url)
        .then(res => res.json())
        .then((data) => this.setState({ pricedata: data }))
        .then(()=>logEvent("requestPriceData_Success", params))
        .catch((e)=>logEvent("requestPriceData_Failed", params));

    return p;
  }

  // #endregion

  // #region sorting
  SortMode = {BottomStrike:0, TopStrike:1, Mark:2, MaxGain:3, MaxLoss:4, MaxROI:5}
  
  requestSort(mode) {
    this.sortData(mode);
    logEvent("requestSort", {mode:mode});
  }
  
  sortData(mode) {
    const data = this.state.optiondata;

    if (data === null) return;

    let sorter = (a,b) => (a.bottomStrike - b.bottomStrike);
    switch (mode) {
      case this.SortMode.TopStrike:
        sorter = (a,b) => (b.topStrike - a.topStrike);
        break;
      case this.SortMode.Mark:
        sorter = (a,b) =>  (b.mark) - (a.mark);
        break;
      case this.SortMode.MaxGain:
        sorter = (a,b) =>  (a.max_gain) - (b.max_gain);
        break;
      case this.SortMode.MaxLoss:
        sorter = (a,b) =>  (b.max_loss) - (a.max_loss);
        break;
      case this.SortMode.MaxROI:
        sorter = (a,b) =>  (parseFloat(b.max_roi) - parseFloat(a.max_roi));
        break;
      case this.SortMode.BottomStrike:
      default:
        sorter = (a,b) => (a.bottomStrike - b.bottomStrike);
      }

      data.sort(sorter);
      this.setState({optiondata:data});
  }

  // #endregion
  
  // #region selecting
  /*
    this.state.optiondata will contain an array of spreads returned from the Options API
      we'll keep track of which one the user has selected by noting its bottom strike in selected_bottom_strike
  */
  // select the spread with the specified bottom strike
  selectSpread = (selectedBottomStrike) => {
    this.setState({selected_bottom_strike:selectedBottomStrike},()=>this.configureChartData());
    logEvent("spreadSelected",{selected_bottom_strike:selectedBottomStrike})
  }
  // find the spread which is currently selected
  selectedSpread = () => this.state.optiondata.find(spread=>{return spread.bottomStrike===this.state.selected_bottom_strike});
  // when a new batch of spreads arrives, default to simply selecting the second one, unless there's only one
  //  (select the second one to make it clearer to the user that they're selectable)
  defaultSelectedBottomStrike = (input_data) => (input_data.length > 1) ? input_data[1].bottomStrike : input_data[0].bottomStrike;
  // select the default spread
  selectDefaultSpread = () => this.selectSpread(this.defaultSelectedBottomStrike(this.state.optiondata));
  // #endregion


  // #region rendering components
  // determine whether we have enough data to render the UI components which assume that we have spreads and stock price data
  haveResultsToShow = () => ((this.state.optiondata !== null) && (this.state.pricedata !== null) && (this.state.selected_bottom_strike > 0));

  /*
    Figure out which strike represents max loss and which is max gain
  */  
  bottomStrikeIsShort = () => ( 
    (parseInt(this.state.displayed_strategy)===parseInt(OptionStrategy.CreditSpread) && parseInt(this.state.displayed_option_type) === parseInt(OptionType.Call)) ||
    (parseInt(this.state.displayed_strategy)===parseInt(OptionStrategy.DebitSpread) && parseInt(this.state.displayed_option_type) === parseInt(OptionType.Put))
  );
 
  configureChartData = () => {
    if (this.haveResultsToShow()===false) return <></>;

    let pricedata = this.state.pricedata;
    if (pricedata.length===0) {
      return <></>;
    }

    //extract the date range from the stock's price history
    // this will be an array of timestamps, one per day in the pricedata set
    let price_history_dates = pricedata.map(p=>Date.parse(p["time"]).valueOf())

    //determine the range of additional dates to show (beyond the history, up until the option expiration date)
    //  first, grab the last date in the history and the expiration date (these will be strings)
    let last_price_date =  pricedata[pricedata.length-1]["time"]; //assume these are already sorted
    let first_price_date = pricedata[0]["time"];
    let expiry_date = this.state.displayed_expiry;

    let future_date_range = allDatesInRange(last_price_date, expiry_date);
    future_date_range=future_date_range.slice(1); //leave out the first 'future' date as it's really the last history date

    //merge history and future
    let all_dates = [...price_history_dates, ...future_date_range];

    //build a series for the prices
    // (this will only contain values for the history dates)
    let price_series=  pricedata.map(p=>p.close);
    
    //build a series for each of the top and bottom strikes 
    // each series will start at the first_price_date and end at the expiry_date, and contain one point for each date
    //  with the same y value (the strike)
    let selectedSpread = this.selectedSpread();
    let bottom_series = all_dates.map(d=>selectedSpread.bottomStrike);
    let top_series = all_dates.map(d=>selectedSpread.topStrike)

    
    //build the breakeven line
    const bePrice = (parseInt(this.state.displayed_option_type)===parseInt(OptionType.Call))
                    ? selectedSpread.bottomStrike+selectedSpread.mark
                    : selectedSpread.topStrike-selectedSpread.mark;

    let be_series = all_dates.map(d=>bePrice)
    
    /*
      stage the Data parameter for the Chartist chart
    */
    var chartdata = { 
      labels: all_dates,
      series: [
        {name:'price',data:price_series},
        {name:'top',data:top_series},
        {name:'be',data:be_series},
        {name:'bottom',data:bottom_series}
      ] 
    }

    let params={...ChartParams};
    params.axisX = {}
    var dates_to_show = [Date.parse(first_price_date), Date.parse(last_price_date), Date.parse(expiry_date)]

    //add a function to the parameters to cause the X values to display as date
    params.axisX.labelInterpolationFnc = (value) => {
      if (dates_to_show.includes(value)) {
        return new Date(value).toLocaleString('en', {  month: 'short', day: 'numeric' });
      }
      return "";
    }

    //determine which area gets shaded red and which is green
    // (if we're selling the bottom strike, it's a profit occurs )
    let lossunder_series = 'be'; let profitunder_series = 'top';
    if (this.bottomStrikeIsShort()===true) {
      lossunder_series='top' ;
      profitunder_series='be';
    }
    var options=    {
        breakeven_price: bePrice, 
        bottom_strike: selectedSpread.bottomStrike,
        top_strike: selectedSpread.topStrike,
        profitunder_series:profitunder_series,
        lossunder_series:lossunder_series
      };

    this.setState({chartdata:chartdata, chartparams:params, maskoptions:options});
    logEvent("configureChartData_Success");
  }
  
  // On the chart created event, create the mask definition used to mask the graph
  createMasks = (data) => {
    if (!this.state.maskoptions || this.state.maskoptions === null) return;
    const options=this.state.maskoptions;

    var defs = data.svg.querySelector('defs') || data.svg.elem('defs');
        
    const total_ticks = (data.axisY.bounds.max-data.axisY.bounds.min); //how many total ticks on the y-axis
    /*
        add a rectangle to represent the space between the break-even line and the top strike
            this clip path will start at the upper-left corner of the chart and span down to the break-even line
            name this rectangle partialarea-mask-top
    */  
    
    let ticks_below = (data.axisY.bounds.max-options.breakeven_price); //how many ticks is the beline below the top of the chart 
    const above_ratio = (ticks_below  / total_ticks); //% of y axis above the be line
    const rectHeightTop = data.chartRect.padding.top + (data.chartRect.height() * above_ratio);

    defs
    .elem('clipPath', { id: 'plugin-partialarea-mask-top' })
    .elem('rect', {
      x: 0,y: 0,
      width: data.svg.width(),
      height: rectHeightTop  //how far down from the line should we go
    });

    /*
      make a clip-path rectangle for below the break-even line 
        this rectangle will span from the upper-left corner of the chart to the bottom strike
        name this rectangle partialarea-mask-bottom
    */
    ticks_below = (data.axisY.bounds.max - options.bottom_strike); //how many ticks is the bottom strike below the top of the chart
    const below_ratio = (ticks_below / total_ticks);  //% of y axis above the bottom strike line
    const rectHeightBottom = data.chartRect.padding.top + (data.chartRect.height() * below_ratio);

    defs
    .elem('clipPath', { id: 'plugin-partialarea-mask-bottom' })
    .elem('rect', {
        x: 0, y: 0,              //rectangle starts at the uper-left corner of the line
        width: data.svg.width(), //rectangle will always cover the entire width of the chart
        height: rectHeightBottom //how far down from the line should we go
    });

    /*
    console.log("*******CHART*********:")
    console.log(`y axis range: ${data.axisY.bounds.min} to ${data.axisY.bounds.max}`);
    console.log(`data.chartRect.height(): ${data.chartRect.height()}`);
    console.log(`data.chartRect.padding.top: ${data.chartRect.padding.top}`);
    console.log(`breakeven_price: ${options.breakeven_price}`);
    console.log(`top_strike: ${options.top_strike}`);
    console.log(`above_ratio: ${above_ratio}`);
    console.log(`TOP MASK HEIGHT: ${rectHeightTop}`);
    console.log(`bottom_strike: ${options.bottom_strike}`);
    console.log(`below_ratio: ${below_ratio}`);
    console.log(`BOTTOM MASK HEIGHT:${rectHeightBottom}`);
    */

    return defs;
  }

  drawmasks = (data) => {
    if (!this.state.maskoptions || this.state.maskoptions === null) return;
    const options = this.state.maskoptions;
    if(data.type === 'line') {
      if (data.series.name==='be') data.element.attr({'class':'be_line'}) //breakeven is always blue
      if (data.series.name==='top') 
        data.element.attr({'class':(options.profitunder==='top')?'maxgain_line':'maxloss_line'})
      if (data.series.name==='bottom') 
        data.element.attr({'class':(options.profitunder==='be')?'maxgain_line':'maxloss_line'})
    }
    if(data.type === 'area') {
        data.element
        .attr({
            'clip-path': (data.series.name==='be')?'url(#plugin-partialarea-mask-bottom)':'url(#plugin-partialarea-mask-top)'
        });

        let colorClass='ct-area'
        if (data.series.name===options.profitunder_series) colorClass = 'profit_zone'
        if (data.series.name===options.lossunder_series) colorClass = 'loss_zone'


        data.element.attr({
          'class': colorClass
        }
        )
    }
  }

  spreadTable = () => {
    if (this.haveResultsToShow()===false) return <></>;

    const data=this.state.optiondata  
    const selectedSpreadStyle = {
      backgroundColor:'#545b62',
      color: '#ffffff'
    }
    const optiondatatable= (
      <table width="100%" className="table"  >
      <thead>
        <tr>
          <th className="border-top-0" onClick={() => this.requestSort(this.SortMode.BottomStrike)}>Bottom Strike&nbsp;<i className="fas fa-sort"></i></th>
          <th className="border-top-0" onClick={() => this.requestSort(this.SortMode.TopStrike)}>Top Strike&nbsp;<i className="fas fa-sort"></i></th>
          <th className="border-top-0" onClick={() => this.requestSort(this.SortMode.Mark)}>Mark&nbsp;<i className="fas fa-sort"></i></th>
          <th className="border-top-0" onClick={() => this.requestSort(this.SortMode.MaxGain)}>Max Gain&nbsp;<i className="fas fa-sort"></i></th>
          <th className="border-top-0" onClick={() => this.requestSort(this.SortMode.MaxLoss)}>Max Loss&nbsp;<i className="fas fa-sort"></i></th>
          <th className="border-top-0" onClick={() => this.requestSort(this.SortMode.MaxROI)}>Max ROI&nbsp;<i className="fas fa-sort"></i></th>
          </tr>
      </thead>
      <tbody id="spreadRows">
      {
        data.map(opt=> {
          //if (opt.mark>=this.state.displayed_width) return <></>

          return (<tr key={opt.bottomStrike}
            style={ (opt.bottomStrike===this.state.selected_bottom_strike) ? selectedSpreadStyle : {}  }
            onClick={()=>this.selectSpread(opt.bottomStrike)}
          >
          <td>{opt.bottomStrike}</td>
          <td>{opt.topStrike}</td>
          <td>{formatCurrency(opt.mark)}</td>
          <td>{formatCurrency(opt.max_gain)}</td>
          <td>{formatCurrency(opt.max_loss)}</td>
          <td>{formatPct(opt.max_roi*100)}</td>
          </tr>)
        })
      }
      </tbody>
      </table>
   
    );

    return (<div className="card" id="optionTableContainer">
              <div className="card-body">
              <h3>Spreads</h3>
              <div className="card-subtitle">Vertical {optionName(this.state.displayed_option_type)} {strategyName((this.state.displayed_strategy))} for <b>{this.state.displayed_ticker}</b> on <b>{this.state.displayed_expiry}</b></div>
              {optiondatatable}
            </div></div>);
  }

  visualizerTable = () => {
    return (<div className="card" id="chartContainer" style={{visibility:(this.haveResultsToShow()===true)?'visible':'hidden'}}>
              <div className="card-body">
                <h3 >Visualizer <ExplainerPopover>
                Chart depicts the last 3 months of prices for {this.state.displayed_ticker}, and includes the time until the option expires ({this.state.displayed_expiry}).<br/>Overlaid on top are the profit and loss zones and breakeven point for the selected spread
                </ExplainerPopover>
                </h3>            
                <link rel="stylesheet" href="https://cdn.jsdelivr.net/chartist.js/latest/chartist.min.css" />
                <script src="//cdn.jsdelivr.net/chartist.js/latest/chartist.min.js"></script>
                <ChartistGraph 
                  type='Line' 
                  data={this.state.chartdata} 
                  options={this.state.chartparams} 
                  listener={{
                              created: (data)=>this.createMasks(data),
                              draw: (data)=>this.drawmasks(data)
                  }} />
              </div>
            </div>);
  }
  spreadDetailCard = () => {
    if (this.haveResultsToShow()===false) return <></>;

    const selectedSpread = this.selectedSpread();

    const instructions = (this.bottomStrikeIsShort()===true) ? 
      <>Sell the <b>{selectedSpread.bottomStrike}</b> strike<br/>Buy the <b>{selectedSpread.topStrike}</b> strike</>
    : <>Buy the <b>{selectedSpread.bottomStrike}</b> strike<br/>Sell the <b>{selectedSpread.topStrike}</b> strike</>;

    let spreadDetail = <></>;
    if (this.haveResultsToShow()===true) {
      const costOrCredit = (this.state.displayed_strategy===parseInt(OptionStrategy.CreditSpread)) ? "Credit" : "Cost";
      const breakevenAboveOrBelow = (this.bottomStrikeIsShort()===true)?"below":"above";
      const breakevenPrice = (this.state.displayed_option_type===parseInt(OptionType.Call))?selectedSpread.bottomStrike+selectedSpread.mark:selectedSpread.topStrike-selectedSpread.mark;

      spreadDetail = <div>
        {optionName(this.state.displayed_option_type)} {strategyName(this.state.displayed_strategy)}:<br/>{instructions}
        <br/>
        {costOrCredit}: {formatCurrency(selectedSpread.mark)}<br/>
        Max Gain: {formatCurrency(selectedSpread.max_gain)} (ROI: {formatPct(selectedSpread.max_roi*100)})<br/> 
        Breakeven at <b>{formatCurrency(breakevenPrice)}</b> (profit {breakevenAboveOrBelow})
      </div>;
    }

    return (<div className="card" id="spreadContainer" style={{maxHeight:'200px',minHeight:'200px',visibility:(this.haveResultsToShow()===true)?'visible':'hidden'}}>
              <div className="card-body">
                <h3 >Spread Detail</h3>
                {spreadDetail}
              </div>
            </div>);
  }

  legendCard = () => {
    const legendBlurb =      
    <svg width="220" height="120">
       <rect width="100" height="40" className="loss_zone"/>
       <rect width="100" height="40" y="40" className="profit_zone"/>
       <text x="2" y="15"  className="font-light">Loss Zone</text>
       <text x="2" y="55"  className="font-light">Profit Zone</text>
       <text x="2" y="100" className="font-light">Breakeven Line</text>
       <line x1="0" y1="110" x2="100" y2="110" stroke="blue"  strokeWidth="1px"/>
   </svg>;

    return (<div className="card" id="legend" style={{maxHeight:'200px',minHeight:'200px',visibility:(this.haveResultsToShow()===true)?'visible':'hidden'}}>
              <div className="card-body">
                <h3 >Legend</h3>
                {legendBlurb}
              </div>
            </div>);
  }
  inputForm = () => {
    return (<>
      <div className="row">
          <ChainPicker onChainChanged={this.chainChanged} defaultTicker={this.state.selected_ticker} />
        </div>
        <div className="row">
          <button id="go" disabled={!this.formValid()} onClick={this.formSubmitted}  type="submit"  className="btn btn-rounded btn-default btn-outline">
              <i className="fas fa-play-circle  m-r-5"></i>Show
          </button>
          <button id="clear"  disabled={this.haveResultsToShow()===false} onClick={this.resetForm} className="btn btn-rounded btn-default btn-outline" >
            <i className="fas fa-eraser  m-r-5"></i>Clear
          </button>
          <TextField type="number"
                    label="Strike Width" id="spreadWidth"
                    value={this.state.strikewidth}
                    onChange={this.strikeWidthChanged}
                    variant="outlined"
                    helperText="Select spread width"/>
          <RadioGroup  value={parseInt(this.state.selected_strategy)} onChange={this.strategyChanged} name="spreadTypeSelector" id="spreadTypeSelector">
            <FormControlLabel  value={OptionStrategy.CreditSpread} control={<Radio  />} label="Credit Spread"  />
            <FormControlLabel value={OptionStrategy.DebitSpread} control={<Radio />} label="Debit Spread" />
          </RadioGroup>
      </div></>);
  }

  render() {
    const explainerBlurbForPage =<div>
    Spread Finder makes it easy to see, at a glance, all possible <OutboundLink href="https://www.investopedia.com/terms/v/verticalspread.asp">vertical spreads</OutboundLink> on a specific stock for a given expiration and width ('size' of spread).
    Then visualize the profit, loss, and breakeven points overlaid on top of the stock's recent price history
    </div>;  
    const seoDescription="Spread Finder makes it easy to see all possible vertical option spreads on a specific stock and visualize the profit, loss, and breakeven overlaid the stock's recent price history";

    return (
      <Ample currPageTitle="Vertical Spread Finder" explainerBlurb={explainerBlurbForPage} seoDescription={seoDescription}  location={this.props.location.href}>
        <Backdrop style={{zIndex:  1,color: '#fff',}} open={this.state.backdropActive}  >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="white-box">
          {this.inputForm()}
        </div>
        <br />
        <div className="row justify-content-center">
          <div className="col-lg-6 col-sm-12 col-xs-12">
                {this.spreadTable()}
        </div>

        <div className="col-lg-6 col-sm-12 col-xs-12">
          <div className="row justify-content-center">
            <div className="col-lg-12 col-sm-12 col-xs-12">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                  {this.spreadDetailCard()}
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 ">
                  {this.legendCard()}
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-12 col-sm-12 col-xs-12">
              {this.visualizerTable()}
            </div>
          </div>

        </div>
        </div>
      </Ample>);
  }
  // #endregion
}